import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import FerociaLion from "./FerociaLion.inline.svg";
import Eyes from "./Eyes";
import Lasers from "./Lasers";
import * as styles from "./index.module.scss";

const LION_FRAMES = 27;

function getLaserDestination(event) {
  var eyeX = window.innerWidth * 0.475;
  var eyeY = window.innerHeight * 0.32;

  var laserX = event.pageX - eyeX;
  var laserY = event.pageY - eyeY;

  var laserAA = laserY / laserX;
  var laserAngle = (Math.atan(laserAA) * 180) / Math.PI;
  if (laserX < 0) {
    laserAngle = laserAngle + 180;
  }
  return {
    angle: laserAngle,
    x: laserX * 1.5,
    y: laserY * 1.5,
  };
}

export default function Lion() {
  const [fireLaser, setFireLaser] = useState(false);
  const [destination, setDestination] = useState(0);

  useEffect(() => {
    function handleClick(event) {
      if (!fireLaser && event.target.id !== "tail") {
        const destination = getLaserDestination(event);
        setDestination(destination);
        setFireLaser(true);
      }
    }
    const masthead = document.getElementById("masthead");
    masthead.addEventListener("click", handleClick);
    return () => masthead.removeEventListener("click", handleClick);
  }, [fireLaser]);

  const frame = (i) => document.getElementById(`frame${i}`);

  const showCurrentFrame = useCallback((i) => {
    frame(i >= LION_FRAMES ? 1 : i + 1).style.visibility = "visible";
  }, []);

  const hidePreviousFrame = (i) =>
    (frame(i === 0 ? LION_FRAMES : i).style.visibility = "hidden");

  const animateFrames = (_, i) => {
    setTimeout(() => {
      hidePreviousFrame(i);
      showCurrentFrame(i);
    }, 70 * i);
  };

  function toggleAnimation(e) {
    //Total layers, plus additional loop to reset to initial.
    Array(LION_FRAMES + 1)
      .fill()
      .map(animateFrames);
  }

  useEffect(() => {
    showCurrentFrame(0);
  }, [showCurrentFrame]);

  return (
    <div className={styles.content}>
      <Lasers
        fireLaser={fireLaser}
        setFireLaser={setFireLaser}
        destination={destination}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1,
          default: {
            duration: 0.2,
            type: "spring",
            stiffness: 100,
            damping: 15,
          },
        }}
      >
        <Eyes fireLaser={fireLaser} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.5,
          default: {
            duration: 0.5,
            type: "spring",
            stiffness: 100,
            damping: 15,
          },
        }}
      >
        <FerociaLion className={styles.lion} />
      </motion.div>
      <div className={styles.tailOverlay} onClick={toggleAnimation} id="tail" />
    </div>
  );
}
