import React from "react";
import { Helmet } from "react-helmet";
import Masthead from "../components/Masthead";
import * as styles from "./index.scss";

export default function Home() {
  return (
    <div className={styles.rootIndex}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ferocia - Internet Banking. Designed for now.</title>
        <meta
          name="description"
          content="Ferocia is a close-knit team of talented developers and designers driven by distilling complex problems into simple, elegant solutions that customers love."
        />
        <link rel="canonical" href="https://ferocia.com.au" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <html lang="en" />
      </Helmet>
      <Masthead />
    </div>
  );
}
