import React from "react";
import { motion } from "framer-motion";
import * as styles from "./index.module.scss";

const beam = (destination, index) => {
  return {
    focused: {
      y: destination.y,
      x: destination.x,
      opacity: [1, 1, 0, 0],
      rotate: [destination.angle, destination.angle],
      transition: { duration: 0.6, delay: 0.25 + index * 0.1 },
    },
    unfocused: {
      y: 0,
      opacity: 0,
      rotate: 0,
      x: 0,
    },
  };
};

export default function Lasers({ fireLaser, setFireLaser, destination }) {
  const colors = ["#3BD4AE", "#FF6B00", "#FFC844"];
  return (
    <>
      {colors.map((c, i) => (
        <motion.div
          key={`laser-${i}`}
          animate={fireLaser ? "focused" : "unfocused"}
          className={styles.beam}
          initial="unfocused"
          onAnimationComplete={() => setFireLaser(false)}
          style={{
            backgroundColor: c,
            width: typeof window !== `undefined` ? window.innerWidth * 0.01 : 0,
            height:
              typeof window !== `undefined` ? window.innerWidth * 0.0025 : 0,
            transformOrigin: "center center",
          }}
          variants={beam(destination, i)}
        />
      ))}
    </>
  );
}
