import * as React from "react";
import { motion, useMotionValue } from "framer-motion";
import * as styles from "./index.module.scss";

const line = {
  focused: { pathLength: 0 },
  unfocused: { pathLength: 1 },
};

const colors = {
  green: "#3BD4AE",
  orange: "#FF6B00",
  yellow: "#FFC844",
};

const svgProps = (color) => ({
  fill: "transparent",
  strokeWidth: "2",
  stroke: colors[color],
  transition: { duration: 0.1 },
});

const paths = [
  ["M379.43,239.71,240.89,181.46", "green"],
  ["M379.43,239.71l-217-50.93", "green"],
  ["M379.85,240.31l-165.2,51", "orange"],
  ["M281.34,258.08l-122.1,19.61", "orange"],
  ["M205.91,215.33l-69-9", "orange"],
  ["M374.62,239.4,75,238.18", "yellow"],
  ["M379.43,239.71l-143.4-15", "yellow"],
  ["M379.43,239.71,115,257.28", "yellow"],
];

export default function Eyes({ fireLaser }) {
  const pathLength = useMotionValue(1);

  return (
    <motion.svg
      initial={false}
      animate={fireLaser ? "focused" : "unfocused"}
      viewBox="0 0 571.93 451.8"
      className={styles.lasers}
    >
      {paths.map((p, i) => (
        <motion.path
          key={`${p[1]}-${i}`}
          d={p[0]}
          style={{ pathLength }}
          variants={line}
          {...svgProps([p[1]])}
        />
      ))}
    </motion.svg>
  );
}
