import React from "react";
import { motion } from "framer-motion";
import * as styles from "./index.module.scss";
import Lion from "../Lion";

export default function Masthead(contents) {
  let headline = contents?.headline ?? "We make delightful software";
  let subline = contents?.subline ?? "Ferocia builds ";

  return (
    <div className={styles.masthead} id="masthead">
      <Lion />
      <div className={styles.mastheadText}>
        <motion.h1
          className={styles.headline}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 1.5,
            default: {
              duration: 0.2,
              type: "spring",
              stiffness: 100,
              damping: 15,
            },
          }}
        >
          {headline}
        </motion.h1>
        <motion.p
          className={styles.subline}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 2,
            default: {
              duration: 0.2,
              type: "spring",
              stiffness: 100,
              damping: 15,
            },
          }}
        >
          {subline}{" "}
          <a className={styles.link} href="https://up.com.au">
            Up
          </a>
        </motion.p>
      </div>
    </div>
  );
}
